import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faComment } from '@fortawesome/free-solid-svg-icons';
// import CommentIcon from '@mui/icons-material/Comment';
// import CommentIcon from '@mui/icons-material/ChatBubbleOutline';
// import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';


// import ReplyIcon from '@mui/icons-material/Reply';

// import CommentIcon from '@mui/icons-material/ChatBubbleOutline';
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
// import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
// import IosShareIcon from '@mui/icons-material/IosShare';

// import BalanceIcon from '@mui/icons-material/Balance';
// import LinkIcon from '@mui/icons-material/Link';
// import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import './Message.css';

const Message = ({ isMobile, message }) => {
  // const navigate = useNavigate();

  // const handleIconClick = (event, type, id) => {
  //   event.stopPropagation();
  //   console.log(`Icon ${type} clicked`);
  //   // Add the relevant function for each icon here
  //   // goToMessageDetail(id);
  // };

  // const goToMessageDetail = (messageId) => {
  //   navigate(`/message/${messageId}`);
  // };

  return (
    <div className={isMobile ? "message-mobile" : "message"}>
      <span className='inline-container'>
        <Avatar className='avatar' sx={{ width: 80, height: 80 }} variant="square" alt={`${message.tokenId}`} src={message.image} />
        <div className="message-content">

          <p className='subtext'>{`Lord ${message.tokenId}`}</p>
          <p className='maintext'>{message.text}</p>
        </div>
      </span>
      <div className="icon-container">
        {/* <Tooltip title="Reply">
          <CommentIcon
            fontSize="small"
            className="icon customize-icons"
            onClick={(event) => handleIconClick(event, 'viewComments', message._id)}
          />
        </Tooltip>
        <Tooltip title="Request Elaboration">
          <QuestionMarkIcon
            fontSize="small"
            className="icon customize-icons"
            onClick={(event) => handleIconClick(event, 'test1')}
          />
        </Tooltip>  
        <Tooltip title="Like">
          <FavoriteBorderIcon
            fontSize="small"
            className="icon customize-icons"
            onClick={(event) => handleIconClick(event, 'test2')}
          />
        </Tooltip>  
        <Tooltip title="Share">
          <IosShareIcon
            fontSize="small"
            className="icon customize-icons"
            onClick={(event) => handleIconClick(event, 'test4')}
          />
        </Tooltip>   */}
      </div>
    </div>
  );
};

export default Message;
