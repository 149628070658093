import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';

import './Symposium.css';

import LordSelect from './LordSelect';
import TextArea from './TextArea';
import Feed from './Feed';
import SelectPills from './SelectPills';
import LoadingSpinner from './LoadingSpinner';
import useInfiniteScroll from '../hooks/useInfiniteScroll';

let socket;

const Symposium = ({ isMobile, connectWallet, connectedWallet, signMessage, selectedLord, setSelectedLord, selectLordIsOpen, toggleSelectLord, usersLords }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [messageIsPending, setMessageIsPending] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [skip, setSkip] = useState(0);
  const [isMore, setIsMore] = useState(true);
  const [limit, setLimit] = useState(20);
  // const ENDPOINT = 'localhost:3030'; 
  // const ENDPOINT = 'https://www.dimenschen.app/symposium';
  const ENDPOINT = 'https://www.dimenschen.app';


  const scrollRef = useRef();

  useEffect(() => {
    socket = io(ENDPOINT, { path: '/symposium' });
    getMessages();

    return () => {
        socket.disconnect();
    };
  }, [ENDPOINT]);

  useEffect(() => {

      socket.on(`deliverNewMessage`, (newMessage) => {

        // Update the state with the new message
        setMessageList((prevMessages) => [newMessage, ...prevMessages]);
        // for each new message received, increment skip by 1 for future pagination calls
        setSkip((prevSkip) => prevSkip + 1);
        setMessageIsPending((prevMessageIsPending) => prevMessageIsPending ? setMessageIsPending(false) : null)
      });

      socket.on(`deliverMessagesPayload`, (messagesPayload) => {
        setMessageList((prevMessages) => [...prevMessages, ...messagesPayload]);
        if (messagesPayload.length < limit) setIsMore(false);

        setSkip((prevSkip) => prevSkip + limit);
        setIsLoading(false);
      });
  }, []);

  // Assuming this is a part of your React component...
  const sendMessage = async () => {
    // lord is the selected lord
    if (!selectedLord) return;
    setMessageIsPending(true);

    const signature = await signMessage();
    const payload = {
      tokenId: selectedLord.tokenId,
      signature,
      adjectives: selected.map(item => item.value),
    };

    // Emit 'postMessage' event to the server with the payload
    socket.emit('postMessage', payload);
    setSelected([]);
  }


  const getMessages = async () => {
    if (isLoading || !isMore) return;
    setIsLoading(true);

    const payload = {
      skip,
      limit,
    };
    socket.emit('getMessages', payload);
  }

  useInfiniteScroll(getMessages, 0.2, scrollRef);

  return (
    <div ref={scrollRef} className="feed">
      <div className="lord-post">
        <LordSelect connectWallet={connectWallet} connectedWallet={connectedWallet} selectedLord={selectedLord} setSelectedLord={setSelectedLord} selectLordIsOpen={selectLordIsOpen} toggleSelectLord={toggleSelectLord} usersLords={usersLords} />
        {
          selectedLord
          ?
          <SelectPills selected={selected} setSelected={setSelected} />
          :
          null
        }
      </div>
      <div className="content">
        <TextArea sendMessage={sendMessage} selectedLord={selectedLord} selected={selected} />
      </div>
      {
        messageIsPending
        ?
        <LoadingSpinner />
        :
        null
      }
      <div className="content">
        <Feed isMobile={isMobile} isLoading={isLoading} messageList={messageList} getMessages={getMessages} />
      </div>
    </div>
  )
}

export default Symposium;