import React from 'react';
import LoadingSpinner from './LoadingSpinner';
import Message from './Message';

import './Feed.css';

const Feed = ({ isMobile, messageList, isLoading }) => {
  return (
    <div  className={isMobile ? "feed" : "feed-mobile"}>
      {
        messageList.length > 0
        ?
        messageList.map((message, index) => {
          return (
            <Message key={index} isMobile={isMobile} message={message} />
          );
        })
        :
        null
      }
      {
        isLoading
        ? 
        <LoadingSpinner />
        :
        null
      }
    </div>
  );
};

export default Feed;