
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { abbreviateAddress } from '../helpers/utils';

import './Header.css';

const DISCORD = 'https://discord.com/invite/GdXUjhy5EK';
// const LINKS_LINK = 'https://linktr.ee/thelyceum42';
// ADD TWITTER
// ?? ELSE

const Header = ({ isMobile, connectWallet, disconnectWallet, connectedWallet, ensName }) => {
  const navigate = useNavigate();

  const goToSymposiumDetail = () => {
    navigate(`/`);
  };

  const goToBuyDetail = () => {
    navigate(`/buy/`);
  };

  let size = "fa-2x";
  if (isMobile) {
    size = "fa-1x";
  }

  return (
    <div className="header">
      <div className="left">
        {
          connectedWallet
          ?
          <span className='wallet' onClick={() => disconnectWallet()}>{`${ensName || abbreviateAddress(connectedWallet)}`}</span>
          :
          <span className='wallet' onClick={() => connectWallet()}>{`CONNECT`}</span>
        }
      </div>
      <div className="right">
        <span className='symposium' onClick={() => goToSymposiumDetail()}>
          SYMPOSIUM
        </span>
        <span className='discord' onClick={() => window.open(DISCORD, '_blank')}>
          DISCORD
        </span>
        <span className='buy' onClick={() => goToBuyDetail()}>
          BUY
        </span>
      </div>
    </div>
  )
}


export default Header;