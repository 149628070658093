

import React from "react";

import './BuyDetail.css';

import LordPreview from './LordPreview';

import GEOMETER from '../images/GEOMETER_BLANK.jpg';
import PERIPATETIC from '../images/PERIPATETIC_BLANK.jpg';
import CHECKS1 from '../images/checks1.png';
import CHECKS2 from '../images/checks2.png';
import CHECKS3 from '../images/checks3.png';


const ETHERSCAN = 'https://etherscan.io/tx/';
// const ETHERSCAN = 'https://evm.explorer.canto.io/tx/';

const yellow = '#ffdec0';

const OPENSEA = 'https://opensea.io/collection/lordsoflyceum';
const NFTX = 'https://nftx.io/vault/0xc4859e2e9efc0a79c39d9abe727b9586625232d0/buy/';
const BLUR = 'https://blur.io/collection/lordsoflyceum';
// const GEM = 'https://pro.opensea.io/collection/lordsoflyceum';
// const ALTO = 'https://alto.build/collections/lords-of-lyceum';
const OPENSEA_G = 'https://opensea.io/collection/geometersofthelyceum';
const OPENSEA_P = 'https://opensea.io/collection/peripateticsofthelyceum';

const OS_CHECKS = 'https://opensea.io/collection/checks-lords-edition';
const CHECKS1_MINT = 'https://app.manifold.xyz/c/lord-ch3ck';
const CHECKS2_MINT = 'https://app.manifold.xyz/br/lord-ch3ck-g3om3t3r';
const CHECKS3_MINT = 'https://app.manifold.xyz/br/lord-ch3ck-dim3nsch3n';


// TODO migrate mintLords and txHash logic over
// Most of the logic can probably be contained in this component instead of being passed down from App.js
const BuyDetail = ({ totalSupply, maxSupply, publicPriceDisplay, publicSaleLive, connectedWallet, pendingTXModalIsOpen, pendingLanguage, connectWallet, mintLords, txHash }) => {
  return (
    <div className="buy-container">
      <div className="column">
        <div className="title">
          <p onClick={()=> window.open(OPENSEA, '_blank')}>Lords of Lyceum</p>
        </div>
      </div>
      <div className="lords-row">
        <div className="lords-column">
          <LordPreview />
        </div>
        <div className="lords-column">
          <div>
            <p>{`Progress: ${totalSupply}/${maxSupply}`}</p>
            <p>{`Price: ${publicPriceDisplay} Ξ per`}</p>
          </div>
          {
            connectedWallet && publicSaleLive
            ?
            <div>
              {
                pendingTXModalIsOpen
                ?
                <p style={{ padding: '0.5em', fontSize: '1.25em', cursor: 'pointer' }} onClick={() => window.open(`${ETHERSCAN}${txHash}`, '_blank')}>{pendingLanguage}</p>
                :
                <div>
                  <div style={{
                    padding: '0.5em',
                  }}>
                  <label htmlFor="count" className="label">Quantity: </label> 
                  <select 
                      name="count" 
                      id="count" 
                      className="quantity"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                  </select>
                  <div style={{ display: 'flex', justifyContent: 'center', padding: '0.5em' }}>
                    <button 
                      className="mint"
                      onClick={() => mintLords()}
                    >
                      Mint
                    </button>
                  </div>
                </div>
              </div>
              }
            </div>
            :
            <div>
              {
                connectedWallet && !publicSaleLive
                ?
                <p style={{ padding: '0.5em', fontSize: '1.25em', cursor: 'pointer' }}>{`SALE IS NOT ACTIVE`}</p>
                :
                <p style={{ padding: '0.5em', fontSize: '1.25em', cursor: 'pointer' }} onClick={() => connectWallet()}>{`CONNECT`}</p>
              }
            </div>
          }
        </div>
      </div>
      <div className="column">
        <div>
          <p className="lords-description">{`Lords of Lyceum is a collection of 10,000 PFPs, hand-drawn and programmatically generated by snooplord. Each Lord's traits were selected from a pool of over 2,000 unique items including a $VOICE assignment between 2-17.`}</p>
        </div>
        <div className="lord-links">
          <p onClick={()=> window.open(BLUR, '_blank')}>{`BLUR`}</p>
          <p onClick={()=> window.open(OPENSEA, '_blank')}>{`OS`}</p>
          <p onClick={()=> window.open(NFTX, '_blank')}>{`NFTX`}</p>
          {/* <p onClick={()=> window.open(ALTO, '_blank')}>{`ALTO`}</p> */}
        </div>
      </div>
      <div className="lyceum-row">
        <div className="column">
          <div className="title">
            <p onClick={()=> window.open(OPENSEA_P, '_blank')}>Peripatetics</p>
          </div>
          <img className="lyceum-image" src={PERIPATETIC} />
          <p className="description" >Peripatetic Passes (111) give holders access to the discord and are worth 10 $VOICE each.</p>
          <div className="lord-links">
            <p onClick={()=> window.open(OPENSEA_P, '_blank')}>{`OS`}</p>
          </div>
        </div>
        <div className="column">
          <div className="title">
            <p onClick={()=> window.open(OPENSEA_G, '_blank')}>Geometers</p>
          </div>
          <img className="lyceum-image" src={GEOMETER} />
          <p className="description" >Geometer Passes (10) give holders access to the discord and are worth 25 $VOICE each.</p>
          <div className="lord-links">
            <p onClick={()=> window.open(OPENSEA_G, '_blank')}>{`OS`}</p>
          </div>
        </div>
      </div>
      {/* <div className="title">
        <p onClick={()=> window.open(OS_CHECKS, '_blank')}>CH3CKS - LORDS 3DITION</p>
      </div> */}
      <div className="collection-row">
        <div className="checks-column">
          <div className="title">
            <p>LORD</p>
          </div>
          <img className="checks-image" src={CHECKS1} />
          <p className="description" >Claim up to 3 LORD CH3CKS per wallet (MAX 103)</p>
          <div className="lord-links">
            <p className="mint-text" onClick={()=> window.open(CHECKS1_MINT, '_blank')}>{`MINT`}</p>
          </div>
        </div>
        <div className="checks-column">
          <div className="title">
            <p>G3OM3T3R</p>
          </div>
          <img className="checks-image" src={CHECKS2} />
          <p className="description" >Burn 3 LORD CH3CKS for a G3OM3T3R CH3CK (MAX 34)</p>
          <div className="lord-links">
            <p className="mint-text" onClick={()=> window.open(CHECKS2_MINT, '_blank')}>{`MINT`}</p>
          </div>
        </div>
        <div className="checks-column">
          <div className="title">
            <p>DIM3NSCH3N</p>
          </div>
          <img className="checks-image" src={CHECKS3} />
          <p className="description" >Burn 3 G3OM3T3R CH3CKS for a DIM3NSCH3N CH3CK (MAX 11)</p>
          <div className="lord-links">
            <p className="mint-text" onClick={()=> window.open(CHECKS3_MINT, '_blank')}>{`MINT`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyDetail;