import { useEffect } from 'react';

const useInfiniteScroll = (callback, thresholdPercentage, scrollRef) => {
  useEffect(() => {
    if (!scrollRef.current) return;

    const handleScroll = () => {
      const scrollHeight = scrollRef.current.scrollHeight;
      const scrollTop = scrollRef.current.scrollTop;
      const clientHeight = scrollRef.current.clientHeight;

      const scrollPosition = scrollTop + clientHeight;
      const scrollThreshold = scrollHeight - scrollHeight * thresholdPercentage;

      if (scrollPosition >= scrollThreshold) {
        callback();
      }
    };

    const currentRef = scrollRef.current;
    currentRef.addEventListener('scroll', handleScroll);

    return () => {
      currentRef.removeEventListener('scroll', handleScroll);
    };

  }, [callback, thresholdPercentage, scrollRef]);
};

export default useInfiniteScroll;