import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ApolloProvider } from "@apollo/client";
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { ethers } from 'ethers';
import { INFURA_ID } from "./helpers/constants";
import Web3Modal from 'web3modal';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import WalletConnectProvider from '@walletconnect/web3-provider';

import client from "./apolloClient";

import './App.css';

import Header from './components/Header';

import BuyDetail from './components/BuyDetail';
import UsersLordsTable from './components/UsersLordsTable';
import Symposium from './components/Symposium';


import LordsContract from './contracts/LordsABI.json';

import { abbreviateAddress } from './helpers/utils';


const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: INFURA_ID, // required
      rpc: {
        1: `https://mainnet.infura.io/v3/${INFURA_ID}`, // mainnet 
        // 5: `https://goerli.infura.io/v3/${INFURA_ID}`,
        // 7077: `https://canto.slingshot.finance`,
      },
    }
  },
  coinbasewallet: {
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: "Coinbase", // Required
      infuraId: INFURA_ID, // Required
      chainId: 1, //4 for Rinkeby, 1 for mainnet (default)
    },
  },
}

const web3Modal = new Web3Modal({
  network: "mainnet",
  // network: "canto",
  theme: "dark", // optional, 'dark' / 'light',
  cacheProvider: false, // optional
  providerOptions, // required
});

export default function App() {
  const [appEthersProvider, setEthersProvider] = useState(null);
  const [lordsContract, setLordsContract] = useState(null);
  const [maxSupply, setMaxSupply] = useState(null);
  
  const [totalSupply, updateTotalSupply] = useState(null);
  const [publicPrice, updatePublicPrice] = useState(null);
  const [publicPriceDisplay, updatePublicPriceDisplay] = useState(null);
  const [connectedWallet, updateConnectedWallet] = useState(null);
  const [ensName, updateEnsName] = useState(null);
  const [lordCount, updateLordCount] = useState(null);
  const [usersLords, updateUsersLords] = useState([]);

  const [pendingTXModalIsOpen, togglePendingTXModal] = useState(false);
  const [txHash, updateTxHash] = useState('');
  const [pendingLanguage, updatePendingLanguage] = useState('');
  const [publicSaleLive, updatePublicSaleIsActive] = useState('');

  const [loadingWallet, updateLoadingWallet] = useState(false);
  const [selectLordIsOpen, toggleSelectLord] = useState(false);

  const [selectedLord, setSelectedLordToState] = useState(null);

  async function loadContractDetails(contract) {
    try {
      const lordsTotalSupply = await contract.totalSupply();
      updateTotalSupply(lordsTotalSupply);
      const lordsMaxSupply = await contract.maxSupply();
      setMaxSupply(lordsMaxSupply);
      const pricing = await contract.saleConfig();
      updatePublicPriceDisplay(ethers.utils.formatEther(pricing.publicPrice));
      updatePublicPrice(pricing.publicPrice);
      const publicSaleIsActive = await contract.publicSaleIsActive();
      // console.log({publicSaleIsActive});
      updatePublicSaleIsActive(publicSaleIsActive);

      setLordsContract(contract);

      contract.on("Transfer", async (from, to, tokenId) => {
        const lordsTotalSupply = await contract.totalSupply();
        updateTotalSupply(lordsTotalSupply);
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function connectWallet() {
    updateLoadingWallet(true);
    try {
      const web3Provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(web3Provider);
      setEthersProvider(library);
      const web3Accounts = await library.listAccounts();
      const address = web3Accounts[0];
      const network = await library.getNetwork();
      const networkId = network.chainId;
      const networkMap = {
        '1': 'mainnet',   
        // '5': 'goerli',
        // '7700': 'canto',
      }

      const whichNetwork = networkMap[networkId]; // "rinkeby"

      if (!whichNetwork) {
        alert(`Connect to Ethereum network and refresh the page to continue`);
        return;
      }

      if (whichNetwork === 'mainnet') {
        const ensName = await library.lookupAddress(address);
        updateEnsName(ensName);
        
        // ens avatar
        const resolver = !ensName ? null : await library.getResolver(ensName);
        const ensAvatar = !resolver ? null : await resolver.getText("avatar");
        // console.log({ensAvatar});
      }
      updateConnectedWallet(address);

      const signer = library.getSigner();

      const thisLordsContract = new ethers.Contract(
        LordsContract.networks[whichNetwork].address,
        LordsContract.networks[whichNetwork].abi,
        signer,
      );

      await loadContractDetails(thisLordsContract);
      await buildLordSummary(address);
      updateLoadingWallet(false);
    } catch (error) {
      console.log(error);
      updateLoadingWallet(false);
    }
  };

  async function disconnectWallet() {
    await web3Modal.clearCachedProvider();
    updateConnectedWallet(null);
    updateEnsName(null);
  };

  async function mintLords() {
    try {
      updateTxHash('');
      updatePendingLanguage('Please confirm transaction with your wallet');
      togglePendingTXModal(true);
    
      const count = document.getElementById("count").value;

      const totalPrice = ethers.BigNumber.from(publicPrice).mul(count);
      let fullHash;
      let tempHash;

      const tx = await lordsContract.publicSaleMint(count, { value: totalPrice });
      fullHash = tx.hash;
      tempHash = abbreviateAddress(fullHash);
      updateTxHash(fullHash);
      updatePendingLanguage(`Transaction pending: ${abbreviateAddress(fullHash)}`);
      const receipt = await tx.wait();
      const lordsTotalSupply = await lordsContract.totalSupply();
      updateTotalSupply(lordsTotalSupply);
      
      if (receipt.status === 1) {
        updatePendingLanguage(`Transaction successful: ${tempHash}`);
        alert(`Transaction successful: ${receipt.transactionHash}`);
      } else {
        updatePendingLanguage(`Transaction failed: ${tempHash}`);
        alert(`Transaction failed: ${receipt.transactionHash}`);
      }
      await buildLordSummary(connectedWallet);
      togglePendingTXModal(false);
    } catch (error) {
      console.log('something went wrong');
      updateTxHash('');
      togglePendingTXModal(false);
    }
  }

  async function buildLordSummary(address) {
    const lordsUrl = `https://www.dimenschen.app/lords/lords/${address}`;
    const lordsRes = await axios.get(lordsUrl)
      .catch(err => {
        console.log('failure');
        console.log({err});
      });  

    updateLordCount(lordsRes.data.length);
    updateUsersLords(lordsRes.data);

    // TODO only prompt this if user is on main page?
    if (lordsRes.data.length) toggleSelectLord(!selectLordIsOpen)
  }

  const signMessage = async () => {
    if (appEthersProvider) {
      const signer = appEthersProvider.getSigner();
      const address = await signer.getAddress();

      const timestamp = Date.now().toString(); // current timestamp
      const message = ethers.utils.arrayify(ethers.utils.id(timestamp));

      const signature = await signer.signMessage(message);
      return { address, message, signature };
    }
  };

  const setSelectedLord = (lord) => {
    setSelectedLordToState(lord);
    toggleSelectLord(!selectLordIsOpen);
  }

  return (
    <ApolloProvider client={client}>
      <div className="App">
        <Router>
          <UsersLordsTable selectLordIsOpen={selectLordIsOpen} toggleSelectLord={toggleSelectLord} usersLords={usersLords} setSelectedLord={setSelectedLord} />
          <Header isMobile={isMobile} connectedWallet={connectedWallet} ensName={ensName} connectWallet={connectWallet} disconnectWallet={disconnectWallet} />
          <Routes>
            <Route 
              path="/"
              element={<Symposium isMobile={isMobile} connectWallet={connectWallet} connectedWallet={connectedWallet} selectedLord={selectedLord} setSelectedLord={setSelectedLord} selectLordIsOpen={selectLordIsOpen} toggleSelectLord={toggleSelectLord} usersLords={usersLords} signMessage={signMessage} />}
            />
            <Route path="/buy" element={<BuyDetail isMobile={isMobile} totalSupply={totalSupply} maxSupply={maxSupply} publicPriceDisplay={publicPriceDisplay} publicSaleLive={publicSaleLive} connectedWallet={connectedWallet} pendingTXModalIsOpen={pendingTXModalIsOpen} pendingLanguage={pendingLanguage} connectWallet={connectWallet} mintLords={mintLords} txHash={txHash} />} />
          </Routes>
        </Router>
      </div>
    </ApolloProvider>
  );
}

