import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";


const httpLink = createHttpLink({
  uri: "http://localhost:4000/graphql", // Replace this with your GraphQL server URL
  // uri: process.env.GRAPHQL_CONNECTION_STRING,
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

export default client;