import React, { useState } from 'react';

import './SelectPills.css';

// import people from '../helpers/people';
import adjectives from '../helpers/adjectives';
// import perspectives from '../helpers/perspectives';

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap the elements
  }
  return array;
}

// const fullList = people.concat(adjectives).concat(perspectives);
// randomly shuffle array so users see different options each time
const items = shuffleArray(adjectives.map((item, idx) => ({ unique_key: idx, value: item })));


const Pill = ({ children, onClick }) => (
  <div 
    onClick={onClick}
    className="pills"
  >
    {children}
  </div>
);

const SelectPills = ({ selected, setSelected }) => {
  const [options, setOptions] = useState(items);
  const [search, setSearch] = useState('');

  const handleSelect = (item) => {
    setSelected([...selected, item]);
    setOptions(options.filter(i => i.unique_key !== item.unique_key));
    setSearch('');
  }

  const handleUnselect = (item) => {
    setSelected(selected.filter(i => i.unique_key !== item.unique_key));
    setOptions([...options, item]);
  }

  const handleSearch = (event) => {
    setSearch(event.target.value);
  }

  const handleAddCustomValue = (event) => {
    event.preventDefault();

    if (search !== '' && items.find(i => i.value === search) === undefined) {
      handleSelect({ unique_key: Date.now(), value: search });
    }
  }

  const filteredOptions = options.filter(i => i.value.toLowerCase().includes(search.toLowerCase()));

  return (
    <div className="customize_container">
      <h4 className='selection-header'>SEED</h4>
      <div className="pill-container">
        {selected.map(item => 
        <Pill key={item.unique_key} onClick={() => handleUnselect(item)}>
          {item.value}
        </Pill>
      )}
      </div>
      <h4 className='selection-header'>OPTIONS</h4>
      <div className="pill-container">
        {filteredOptions.map(item =>
        <Pill key={item.unique_key} onClick={() => handleSelect(item)} color="gray">
          {item.value}
        </Pill>
      )}
      </div>
      <div className="search">
        <input className="text-input" type="text" value={search} onChange={handleSearch} placeholder="Search / Add Custom" />
        <button className="add-button" onClick={handleAddCustomValue}>Add</button>
      </div>
    </div>
  );
};

export default SelectPills;
