import React from 'react';
// import Switch from '@mui/material/Switch';
// import Stack from '@mui/material/Stack';
// import Typography from '@mui/material/Typography';
import './TextArea.css';

// const PillsToDisplay = [
  // 'IDEOLOGY',
  // 'BOOK TITLE',
  // 'BOOK AUTHOR',
  // 'TYPE',
  // 'HAIR',
  // 'BODY',
  // 'EAR',
  // 'EYES',
  // 'FACIAL HAIR',
  // 'GLASSES',
  // 'HAT',
  // 'MOUTH',
  // 'VOICE',
  // 'FLAG',
// ];


// const Pill = ({ children, onClick }) => (
//   <div 
//     onClick={onClick}
//     className="pills"
//   >
//     {children}
//   </div>
// );


function joinFirstThreeValues(arr) {
  const values = arr.slice(0, 3).map(item => item.value);
  if (arr.length > 3) {
    return values.join(', ') + '...';
  }
  return values.join(', ');
}


const TextArea = ({ selectedLord, sendMessage, selected }) => {
  // const [text, setText] = useState('');
  // const [mode, setMode] = useState('autonomous');

  const handleSubmit = (e) => {
    // based on the mode, call the appropriate API calls
    e.preventDefault();
    sendMessage();
    // if (!text) return;
    // setText('');
  };

  // const handleSwitch = (e) => {
  //   if (mode === 'autonomous') setMode('mask');
  //   if (mode === 'mask') setMode('autonomous');
  //   // call setMode to achieve whatever we want
  // }

  // let hairArray = selectedLord ? selectedLord.HAIR.trim().split(' ') : '';
  // hairArray.length > 1 ? hairArray.pop() : null;
  // const hairFormat = hairArray ? hairArray.join(' ').toUpperCase() : null;

  return (
    <form className="text-area-container" onSubmit={handleSubmit}>
    {/* <form className="text-area-container"> */}
      {
        selectedLord
        ?
        <div className="autonomous-wrapper">
          <button type="submit" className="mask-submit-button">{`Post as ${joinFirstThreeValues(selected)} ${selectedLord.IDEOLOGY} Lord ${selectedLord.tokenId}`}</button>
        </div>
        :
        null
      }
        {/* {
          selectedLord 
          ?
          <div className="text-area-wrapper">
            <textarea
              className="text-area"
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder={`Enter custom text for Lord #${selectedLord.tokenId}, an AI Agent, to transform into their own words...`}
            />
            <button type="submit" className="submit-button">Post</button>
          </div>
          :
          <div className="autonomous-wrapper">
            <button type="submit" className="mask-submit-button">Post</button>
          </div>
        } */}
        {/* <div className="switch-container">
          <p>Autonomous</p>
          <label className="switch">
            <input type="checkbox" onClick={handleSwitch}/>
            <span className="slider round"></span>
          </label>
          <p>Mask</p>
        </div> */}
      {/* <div>
        <button onClick={signMessage}>
          Sign Message
        </button>
      </div> */}
    </form>
  );
};

export default TextArea;