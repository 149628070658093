
import './LordPreview.css';

import HairGif from '../images/Hair_preview.gif';
import EyesGif from '../images/Eyes_preview.gif';
import FacialHairGif from '../images/Facial_Hair_preview.gif';
import MouthsGif from '../images/Mouths_preview.gif';
import AccessoriesGif from '../images/Accessories_preview.gif';
import GlicpixGif from '../images/Glicpix_preview.gif';


const renderTraitsPreview = ({isMobile}) => {

  return (
      <div className={isMobile ? 'gif-mobile' : 'gif'}>
        <div className="item">
          <img className={isMobile ? 'logo-mobile' : 'logo'} src={GlicpixGif} alt="preview" />
        </div>
        <div className="item">
          <img className={isMobile ? 'logo-mobile' : 'logo'} src={HairGif} alt="preview" />
        </div>
        <div className="item">
          <img className={isMobile ? 'logo-mobile' : 'logo'} src={FacialHairGif} alt="preview" />
        </div>
        <div className="item">
          <img className={isMobile ? 'logo-mobile' : 'logo'} src={EyesGif} alt="preview" />
        </div>
        <div className="item">
          <img className={isMobile ? 'logo-mobile' : 'logo'} src={MouthsGif} alt="preview" />
        </div>
        <div className="item">
          <img className={isMobile ? 'logo-mobile' : 'logo'} src={AccessoriesGif} alt="preview" />
        </div>
      </div>
  )
}

export default renderTraitsPreview;