import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './LordSelect.css';



const LordSelect = ({ connectWallet, connectedWallet, selectedLord, selectLordIsOpen, toggleSelectLord }) => {
  return (
    <div>
      {
        selectedLord ?
        <div className="selected-lord">
          <span className="lord-title" onClick={() => toggleSelectLord(!selectLordIsOpen)}>
            <p className="name">{`LORD ${selectedLord.tokenId}`}</p>
            <ArrowDropDownIcon 
                fontSize="small"
                className="icon customize-icons"
            />
          </span>
          <div className="image-container" onClick={() => toggleSelectLord(!selectLordIsOpen)}>
            <img 
              className="lord-image"
              src={selectedLord.image} />
          </div>
        </div>
          :
        <div className="unselected-lord">
          <p onClick={!connectedWallet ? () => connectWallet() : () => toggleSelectLord(!selectLordIsOpen)} >{!connectedWallet ? 'Connect Wallet' : 'Select Lord'}</p>
        </div>
      }
    </div>
  )
}


export default LordSelect;


// function determineArticle(word) {
//   // Lowercase the first letter of the word
//   const firstLetter = word.charAt(0).toLowerCase();
//   // List of vowels
//   const vowels = ['a', 'e', 'i', 'o', 'u'];
//   // Check if the first letter is a vowel
//   if (vowels.includes(firstLetter)) {
//       return 'An';
//   } else {
//       return 'A';
//   }
// }
// {
//   `${determineArticle(selectedLord.IDEOLOGY)} ${selectedLord.IDEOLOGY.toUpperCase()} READS ${selectedLord['BOOK AUTHOR'].toUpperCase()}'S
//   ${selectedLord['BOOK TITLE'].toUpperCase()}`
// }
// {/* {
//   `${determineArticle(selectedLord.IDEOLOGY)} ${selectedLord.IDEOLOGY.toUpperCase()} reads ${selectedLord['BOOK AUTHOR'].toUpperCase()}'S
//   ${selectedLord['BOOK TITLE'].toUpperCase()}, ${selectedLord.TYPE.split(' ')[0].toUpperCase()} with ${hairFormat} hair, a ${selectedLord.BODY.toUpperCase()} body, ${selectedLord.EAR ? `${selectedLord.EAR[0].toUpperCase()} ears,` : ''} 
//   ${selectedLord.EYES.toUpperCase()} eyes, ${selectedLord['FACIAL HAIR'] ? `${selectedLord['FACIAL HAIR'].toUpperCase()} facial hair,` : ''} ${selectedLord.GLASSES ? `${selectedLord.GLASSES.toUpperCase()} glasses,` : ''} ${selectedLord.HAT ? `${selectedLord.HAT.toUpperCase()} hat,` : ''}
//   and ${selectedLord.MOUTH.toUpperCase()} mouth`
// } */}
