import React, { useRef, useEffect } from "react";

import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

import './UsersLordsTable.css';

const yellow = '#ffdec0';

const flagBaseUrl = 'https://rose-deep-catfish-22.mypinata.cloud/ipfs/QmaatAruiG12muoa1tFhL2BzZbPCYYiCcGkEv61ShQsPoJ/';
const OPENSEA_SEARCH = 'https://opensea.io/collection/lordsoflyceum?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=FLAG&search[stringTraits][0][values][0]='

const flagsColumns = [
    { 
      field: 'flag',
      headerName: 'Flag', 
      width: 90,
      renderCell: (params) => {
        return (
          <div>
            <img 
              style={{ height: 50 }} 
              src={`${flagBaseUrl}${params.value.replace(/ /g, '-').toLowerCase()}.png`} />
          </div>
        )
      }
    },
    {
      field: 'name',
      headerName: 'Flag Name',
      width: 120,
    },
    {
      field: 'flag_voice',
      headerName: 'Flag Power',
      width: 150,
    },
    { 
      field: 'citizen_images',
      headerName: 'Citizens', 
      width: 500,
      renderCell: (params) => { 
        return params.value.map(citizen => {
          return (
            <img 
              key={citizen}
              style={{ height: 50 }} 
              src={citizen} 
            />
          )    
        })      
      }
    },
    {
      field: 'citizen_ids',
      headerName: 'Citizen Count',
      width: 120,
      valueGetter: (params) => `${params.value.length}`,
    },
    {
      field: 'user_voice',
      headerName: 'Citizen Power',
      width: 150,
    },
    {
      field: 'user_power',
      headerName: 'Wallet Control',
      width: 150,
      // editable: true,
      valueGetter: (params) => `${parseFloat(params.value).toFixed(3)}`,
    },
    {
      field: 'search',
      headerName: '',
      width: 120,
      renderCell: (params) => {
        return (
          <div>
            <button 
              style={{
                paddingLeft: '1em',
                paddingRight: '1em',
                color: yellow,
                backgroundColor: '#020202',
                fontSize: '1em',
                borderRadius: '5px',
                border: 'none',
                cursor: 'pointer',
              }} 
              onClick={() => window.open(`${OPENSEA_SEARCH}${params.value}`, '_blank')}
            >{`VIEW ALL`}
            </button>
          </div>
        )
      }
    },
]

const lordColumns = [
  { 
    field: 'image',
    headerName: 'Image', 
    width: 60,
    renderCell: (params) => {
      return (
        <div>
          <img 
            style={{ height: 50 }} 
            src={params.value} />
        </div>
      )
    }
  },
  { 
    field: 'tokenId',
    headerName: 'ID',
    width: 60,
  },
  {
    field: "IDEOLOGY",
    headerName: "IDEOLOGY",
    width: 150,
  },
  {
    field: "BOOK TITLE",
    headerName: "BOOK TITLE",
    width: 210,
  },
  {
    field: "BOOK AUTHOR",
    headerName: "BOOK AUTHOR",
    width: 150,
  },
  {
    field: "TYPE",
    headerName: "TYPE",
    width: 90,
  },
  {
    field: "HAIR",
    headerName: "HAIR",
    width: 120,
  },
  { 
    field: "BODY",
    headerName: "BODY",
    width: 150,
  },
  {
    field: "EAR",
    headerName: "EAR",
    width: 90,
  },
  {
    field: "EYES",
    headerName: "EYES",
    width: 90,
  },
  {
    field: "FACIAL HAIR",
    headerName: "FACIAL HAIR",
    width: 120,
  },
  {
    field: "GLASSES",
    headerName: "GLASSES",
    width: 120,
  },
  {
    field: "HAT",
    headerName: "HAT",
    width: 120,
  },
  {
    field: "MOUTH",
    headerName: "MOUTH",
    width: 120,
  },
  {
    field: "VOICE",
    headerName: "VOICE",
    width: 60,
  },
  {
    field: "FLAG",
    headerName: "FLAG",
    width: 90,
  },
]


const UsersLordsTable = ({ selectLordIsOpen, toggleSelectLord, usersLords, setSelectedLord }) => {
  if (!usersLords || !usersLords.length) return null;

  const popupRef = useRef();

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      toggleSelectLord();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div>
      {
        selectLordIsOpen
        ?
        <div className="lords-table-popup-container">
          <div className="lords-table-popup" ref={popupRef}>
            <Box sx={{ height: '100%', width: '100%', backgroundColor: yellow, borderRadius: '5px', border: 'none' }}>
              <DataGrid
                rows={usersLords}
                columns={lordColumns}
                rowHeight={60}
                getRowId={(row) => row.tokenId} 
                onRowClick={(e) =>  setSelectedLord(e.row)}
                sx={{
                  color: 'black',
                  cursor: 'pointer',
                }}
              />
            </Box>
            </div>
        </div>
        :
        null
      }
    </div>

  );
}

export default UsersLordsTable;